
@keyframes moveShine {
  0% {
    background-position: -100px;
  }

  100% {
    background-position: 600px;
  }
}

.pd-skeleton {
  margin-top: 20px;

  &__title {
    height: 70px;
    margin-left: 20px;
    margin-bottom: 20px;
  }

  &__single {
    height: 20px;
  }

  &__flex {
    align-items: center;
    display: flex;
    flex-direction: column;

    &-actions {
      align-items: flex-end;
      justify-content: space-between;
    }

    &--end {
      justify-content: end;
    }

    &--autofill {
      flex: 1;
    }
  }

  &__line {
    animation: moveShine 5s infinite linear;
    background-image: linear-gradient(90deg,  #e5e5e5 0px, #f9f9f9 40px,  #e5e5e5 80px);

    border-radius: 10px;
    width: 100%;

    &--light {
      animation: moveShine 5s infinite linear;
      background-image: linear-gradient(90deg,  #e5e5e5 0px, #f9f9f9 40px,  #e5e5e5 80px);
    }
  }
}


