.table {
  text-align: left;
  th{
    border-bottom: 1px solid #ccc;
  }
  tr{
    &:nth-child(even){
      background-color: #f2f2f2;
    }
  }
  td, th {
    padding: 8px;
  }
}
