.container {
  composes: lgt-table__pagination from global;
  margin-bottom: 30px;
  :global .lgt-pagination-item {
    border: 1px solid transparent;
    cursor: default;
  }
  :global .lgt-pagination-item-active {
    border-color: currentColor;
  }
  :global .lgt-pagination-prev, :global .lgt-pagination-next {
    cursor: default;
  }
}

.link {
  composes: lgt-button--link from global;
  composes: lgt-button--secondary-black from global;
  display: block;
  width: 100%;
  text-align: center;
  height: 100%;
  line-height: 33px;
  cursor: pointer;
  text-decoration: none;
}