.heading {
  display: flex;
  align-items: baseline;
}

.icon {
  margin-right: 15px;
}

.accordion {
  border: 1px solid #CBCBCB;
  margin-bottom: 30px;

  ul,li{
    padding: revert;
    margin: revert;
  }
}
