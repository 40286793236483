.link {
  composes: lgt-button from global;
  composes: lgt-button--md from global;
  composes: lgt-button--solid from global;
  text-decoration: none;
  text-transform: capitalize;

  &:hover {
    background-color: #34348C;
    color: #fff;
  }
}

.active {
  composes: lgt-button--primary from global;
  background-color: #4141AF !important;
  &:hover {
    background-color: #4141AF !important;
  }
}
