.content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.btn {
  padding-left: 20px;
  padding-right: 20px;
}
