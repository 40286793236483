.ava {
  width: 26px;
  height: 26px;
  font-size: 26px;
}

.deactivated {
  background: var(--areaBackground);

  :global {
    .lgt-table-cell {
      color: var(--gray);
    }
  }
}
