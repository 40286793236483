.container {
  margin: 64px auto 92px;
}

.top,
.mid,
.bot {
  display: flex;
  justify-content: space-between;
}

.mid {
  margin-top: 28px;
}

.bot {
  justify-content: center;
}

.img {
  width: 460px;
  transform: translate(13px, -52px);
}

.image {
  width: 100%;
  margin-top: -20px;
  transform: translateY(-10px);
}

.icons {
  font-size: 32px;
  color: #4141AF;
  margin-bottom: 30px;
}

.btn {
  margin-bottom: 39px;

  &:hover {
    color: #383838 !important;
    background-color: #ffffff !important;;
    border-color: #383838 !important;
    box-shadow: none;
    cursor: default;
  }
}

.tag {
  position: absolute !important;
  top: 45px;
  right: 18px;

  button {
    background-color: var(--purple) !important;
    border-color: var(--purple) !important;
  }
}

.grayTag {
  position: absolute !important;
  bottom: 45px;
  left: 18px;
}

.link {
  font-weight: 600;
  color: var(--purple);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.paragraph {
  margin-top: 13px;
}
