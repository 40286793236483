.table {
  margin-top: 30px;
  thead {
    --lgt--table-cell-padding: 5px 20px;
    :global .lgt-table-cell {
      height: 45px;
      vertical-align: middle;
    }
  }
  tbody {
    --lgt--table-cell-padding: 5px 20px;
    :global .lgt-table-cell {
      height: 90px;
      &:last-child {
        padding: 0;
      }
    }
  }
}

.filter {
  margin: 30px 0;
  max-width: 300px;
  display: block;
}
