.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.btns {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.input {
  position: relative;
}

.error {
  display: inline-flex;
  font-size: 10px;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  color: var(--danger);
}

.badgeList {
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0;
  gap: 5px
}
