.link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.info {
  font-family: var(--defaultFontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 30px;
}
