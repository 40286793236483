.error {
  margin: 0 auto;
  max-width: 778px;
  padding-top: 50px;
  text-align: center;
}


.title {
  font-family: var(--defaultFontFamily);
  font-style: normal;
  font-weight: 400;
  font-size: 115px;
  line-height: 100px;
  margin-bottom: 40px;
}

.description {
  font-weight: 600;
  line-height: 20px;
  margin: 0 auto;
  max-width: 590px;
  margin-bottom: 100px;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--defaultFontFamily);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  opacity: .45;
  text-decoration: none;
  color: black;
}

.logo {
  svg {
    fill: #929292;
  }
}

