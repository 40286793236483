.updated {
  display: flex;
  justify-content: flex-end;
  font-size: var(--defaultFontSize);
}

.main {
  margin-top: 20px;
  overflow: auto;
}

.article {
  font-style: normal;
  font-weight: 400;
  font-size: var(--primaryFontSize);
  line-height: 20px;
  text-align: justify;

  p {
    margin: 2rem 0;
  }

  ul, ol {
    padding-left: 5rem;
    list-style-position: outside;

    li {
      margin: 40px 0;
    }
  }

  a {
    color: var(--purple);
  }

  .table {
    table {
      width: 100%;
      border-collapse: collapse;
      font-size: var(--smallFontSize);
      line-height: var(--smallFontSize);
    }

    table, td, th {
      border: 1px solid;
    }

    td, th {
      padding: 5px 10px;
      vertical-align: top;
    }

    em {
      font-style: normal;
    }

  }
}