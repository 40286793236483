.header,
.heading,
.menu {
  display: flex;
  align-items: center;
  gap: 30px;
}

.heading {
  gap: 8px;
}

.heading {
  margin: -1.25rem;
}

.header {
  justify-content: space-between;

  .menu {
    .generate {
      background-color: var(--purple);
      padding: 17px 25px;
      border-radius: 6px;
      color:  var(--white);
      display: inline-flex;
      margin-right: 42px;
      transition: .4s ease;

      &:hover {
        background-color: var(--white);
        color: var(--grayDarker);
      }
    }
  }
}

.wrapper {
  height: var(--headerMainHeight);
  display: flex;
  justify-content: space-between;
}

.project {
  color: var(--black);
  font-size: var(--defaultFontSize);
  line-height: var(--bigFontSize);
  font-weight: var(--boldFontWeight);
}

.user {
  color: var(--grayDark);
  font-size: var(--defaultFontSize);
  line-height: var(--bigFontSize);
  font-weight: var(--boldFontWeight);
}

.burger {
  width: 40px;
  height: 40px;
}
