.wrapper {
  min-height: 45px;
  line-height: 45px;
  width: 100%;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 44px;
  }
}
.tooltip {
  transform: translateX(-22px);
  pointer-events: none;
}
.container {
  display: inline-block;
  vertical-align: middle;
  padding-right: 10px;
  width: 100%;
  dt {
    font-size: 13px;
    line-height: 16px;
    overflow: hidden;
  }
  dd {
    font-size: 9px;
    line-height: 11px;
    font-style: italic;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
