.container {
  margin: 30px 0;
}

.textarea {
  margin: 30px 0 20px;
}

.footer {
  display: flex;
  justify-content: space-between;
  row-gap: 20px;
}
