.container {
  width: 330px;
  height: 330px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  flex-direction: column;
}

.small {
  width: 230px;
  height: 230px;
  padding: 30px;
  i {
    margin-bottom: 12px;
  }
}

.middle {
  width: 300px;
  height: 300px;
  padding: 34px;
}

.big {
  width: 330px;
  height: 330px;
  padding: 48px;
}

.huge {
  width: 350px;
  height: 350px;

  .icons {
    margin-bottom: 20px;
  }
}

.gray {
  background-color: rgba(0, 0, 0, 0.03);
}
.purple {
  background-color: rgba(65, 65, 175, 0.06);
}
