.userInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto 20px;
}

.initials {
  margin-bottom: 5px;
}

.user {
  font-family: var(--defaultFontFamily);
  font-weight: var(--boldFontWeight);
  color: var(--black);
  font-size: var(--defaultFontSize);
  line-height: var(--bigFontSize);
  margin-bottom: 2px;
}

.skeleton {
  width: 140px;
  margin-bottom: 5px;
}

.skeletonSmall {
  width: 80px;
  margin-bottom: 5px;
}

.skeletonXs {
  width: 50px;
}

.team {
  font-family: var(--defaultFontFamily);
  color: var(--grayDark);
  font-size: var(--primaryFontSize);
  line-height: var(--defaultFontSize);
  margin-bottom: 2px;
}

.mail {
  font-family: var(--defaultFontFamily);
  color: var(--grayDark);
  font-size: var(--smallFontSize);
  line-height: var(--primaryFontSize);
}
