.container {
  padding: 40px 30px 22px;
  max-width: 400px;
  width: 400px;
}
.title {
  margin-bottom: 25px;
}
.fields {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.btns {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 60px;
}
