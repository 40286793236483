.wrapper {
  min-height: 45px;
  line-height: 45px;
  width: 100%;
}
.container {
  display: inline-block;
  vertical-align: middle;
  padding-right: 10px;
  width: 100%;
  dt {
    font-size: 13px;
    line-height: 16px;
    overflow: hidden;
  }
  dd {
    font-size: 9px;
    line-height: 11px;
    font-style: italic;
  }
}
