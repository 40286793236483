.option {
  height: 36px;
  line-height: 36px;
}

.icon {
  height: 24px;
  width: 24px;
  font-size: 9px;
  margin-right: 10px;
}
