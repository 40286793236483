.table {
  :global .lgt-table-cell {
    padding: 0;
    vertical-align: middle;
  }
  :global .lgt-table-thead .lgt-table-cell {
    height: 46px;
    vertical-align: middle;
  }
  :global .lgt-table__row--expanded {
    background-color: #ECECF7;
  }
  :global .lgt-table-expanded-row.lgt-table__row--expanded {
    padding: 0;
    border-left-color: transparent;
    background-color: #F6F6FB;
  }
  :global .lgt-table__expand-icon{
    padding: 5px;
  }
  :global .lgt-table-row-expand-icon-cell {
    padding-right: 15px;
  }
  .action {
    padding: 0;
  }
  .cell {
    height: 90px;
    min-height: 90px;
    padding: 0 15px;
  }
}

.option {
  height: 36px;
  line-height: 36px;
}

.clear {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  transform: translate(17px, 0px);
  cursor: pointer
}
