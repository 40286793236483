:global {
  .headerMenu {
    .lgt-overlay__header {
      display: none;
    }
  }

  .headerMenu.lgt-overlay {
    width: 302px;
  }

  .burger {
    width: 40px;
    height: 40px;

    &.closed {
      &.lgt-button {
        &.lgt-button--solid {
          &.lgt-button--md {
            background-color: white;
            box-shadow: none;
            border-color: transparent;
          }
        }
      }
    }
  }
}


.list {
  .item {
    line-height: 30px;
    cursor: pointer;
    color: var(--black);
    text-decoration: none;
    display: block;

    &:hover {
      background-color: var(--areaBackground);
    }
  }
}

.container {
  margin: 0 auto;
  padding: 30px 21px;
}

.admin,
.faq {
  border-bottom: 1px solid var(--areaBackground);
  margin-bottom: 9px;
}

.footer {
  margin-top: 30px;
  font-family: var(--defaultFontFamily);
  font-size: var(--primaryFontSize);
  line-height: var(--defaultFontSize);
  color: var(--grayDark);
}
