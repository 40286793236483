.modal {
  :global(.lgt-modal__close-button) {
    display: none;
  }
}

.content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.text {
  margin-bottom: 15px;
  font-size: var(--primaryFontSize);
  line-height: var(--defaultFontSize);

  a {
    font-weight: bold;
    color: var(--black);

    &:hover,
    &:active {
      color: var(--black);
    }
  }
}
