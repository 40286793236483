@import '~@wmgtech/legato/build/css/legato-rm';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.inner {
  width: 100%;
  max-width: 1180px;
  padding: 0 10px;
  margin: 0 auto;
  min-width: 960px;
}

.page {
  margin: 0 auto;
  max-width: 1180px;
  width: 100%;
}

.title {
  margin-bottom: 30px;
  font-size: 20px;
  line-height: 24px;
}

:root {
  --defaultFontFamilyBold: 'SuisseIntlSemiBold';
  --defaultFontFamily: 'SuisseIntl';
  --faFontFamily: 'Font Awesome 6 Pro';

  --storyCollapsedHeight: 350px;
  --headerCollapsedHeight: 40px;
  --headerMainHeight: 163px;
  --gphHeaderHeight: 100px;
  --alertDefaultWidth: 282px;

  // fonts
  --extraSmallFontSize: 9px;
  --smallFontSize: 11px;
  --primaryFontSize: 13px;
  --defaultFontSize: 16px;
  --bigFontSize: 20px;

  --defaultFontWeight: 400;
  --boldFontWeight: 600;
  --headerMainHeight: 163px;

  --purple: #4141AF;
  --white: #fff;
  --grayDark: #646464;
  --grayDarker: #383838;
  --black: #000000;
  --blue: #0032a0;
  --areaBackground: #f4f4f4;
}