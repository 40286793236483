.content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  padding: 30px 50px 50px;

}
.about {
  position: relative;
  height: 190px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
}

.welcome {
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 100px;
}
.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.left {
  background: linear-gradient(67deg,#4141AF .9%,#4141AF 90%,hsla(0,0%,100%,0) 0);
  width: 66.6%;
  z-index: -1;
  height: 190px;
  position: absolute;
  top: 0;
  border-radius: 6px 0 0 6px;
}

.right {
  background: linear-gradient(67deg,#646464 9.13%,#131314 47.48%);
  mix-blend-mode: multiply;
  opacity: .8;
  right: 0;
  width: 38.5%;
  z-index: -2;
}

.img {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -3;
  width: 42%;
  height: 190px;
  background-size: 100%;
  animation: changeBg 40s infinite;
  border-radius: 6px;
}

@keyframes changeBg {
  0%,
  100% {
    background-image: url('../../assets/header/header-4.png');
  }
  25% {
    background-image: url('../../assets/header/header-1.png');
  }
  50% {
    background-image: url('../../assets/header/header-2.png');
  }
  75% {
    background-image: url('../../assets/header/header-3.png');
  }
}
