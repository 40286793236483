.container {
  padding: 30px 70px;
  background-color: #F6F6FB;
}
.title {
  margin-bottom: 10px;
}
.subtitle {
  display: flex;
  font-size: 16px;
  line-height: 20px;
  align-items: center;
  margin-bottom: 25px;
}
.content {
  border-top: 1px solid #E5E5E5;
  padding-top: 25px;
  th {
    text-align: left;
  }
  td {
    padding-top: 10px;
    vertical-align: top;
    &:last-child {
      width: 55%;
    }
  }
}
.copy {
  :global .lgt-icon {
    font-size: 20px;
  }
}
.creator {
  margin-bottom: 10px;
}

.badge {
  background-color: #E2E2F3;
  height: 24px;
  line-height: 24px;
  padding: 0 10px;
  border-radius: 12px;
  display: inline-block;
  font-size: 13px;
}
.list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 5px;
}