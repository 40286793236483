.initials {
  background-color: var(--purple);
  color: var(--white);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
