.notification {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1b181b;
  border-radius: 5px;
  padding: 1rem;

  p {
    margin: 0 1rem 0 0;
    color: white;
    display: flex;
    align-items: center;
  }
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hint {
  text-align: center;
  margin: 1rem 1rem 2rem;

  i {
    margin-right: 5px;
  }
}

.btn {
  border-color: transparent;
}
